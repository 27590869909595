<template>
  <validation-provider
    :rules="rules"
    :name="`«${title}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <div class="hm-form__element-label">
        {{ title }}
        <span v-if="required">*</span>
      </div>
      <esmp-scale
        :description="description"
        :view="viewMode"
        :horizontal="isInline"
        :options="scaleValues"
        :ranges="ranges"
        v-model="localValue"
      />
      <div v-if="v.errors.length" class="hm-form__element-hint">
        {{ v.errors[0] }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';

export default {
  name: 'HmScale',
  mixins: [uid],
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    isInline: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: String,
      default: 'table',
    },
    startRange: {
      type: String,
      default: '',
    },
    endRange: {
      type: String,
      default: '',
    },
    scaleValues: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value ? [this.value] : [];
      },
      set(newValue) {
        this.$emit('input', newValue[0]);
      },
    },
    ranges() {
      return [
        {
          start: this.startRange || '',
          end: this.endRange || '',
        },
      ];
    },
  },
};
</script>
